import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackNavigation } from '../../../controls/back-navigation/back-navigation';
import {
  ActionBar,
  ActionBarCenter,
  ActionBarLeft,
  ActionBarRight
} from '../../../building-blocks/action-bar/action-bar';
import { BreadcrumbNavigation } from '../../../building-blocks/breadcrumb-navigation/breadcrumb-navigation';
import { Button, Dropdown, Form, RadioButton, RadioButtonGroup, TextInput, Toggle } from 'carbon-components-react';
import { Card } from '../../../controls/card/card';
import { ContentView, ContentViewSection, RequiredFormItem } from '../../../building-blocks/content-view/content-view';
import { TextAreaCount } from '../../../controls/text-area-count/text-area-count';
import { ApprovalState, ApprovalType } from '../../../controls/approval-state/approval-state';
import styles from './landlord-view.module.scss';
import { ContactType, Gender } from '../../../../graphql/types/globalTypes';
import classNames from 'classnames';
import { useCreateAndUpdateMutations, useMarkAsSeen, useVerifyAndBlockMutations } from './landlord-view.hooks';
import {
  LandlordFormDataState,
  resetForm,
  setApprovalState,
  setBlockedReason,
  setCity,
  setCompanyName,
  setContactType,
  setEmail,
  setFirstName,
  setFormData,
  setGender,
  setHouseNumber,
  setIsBlocked,
  setLastName,
  setNotes,
  setPostalCode,
  setStreet,
  setTelephoneNumber
} from './landlord-view.state';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../../redux/store';
import { useLazyQuery } from '@apollo/client';
import { GET_LANDLORD_BY_UUID } from '../../../../graphql/queries/landlord';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetLandLordByUUID, GetLandLordByUUIDVariables } from '../../../../graphql/types/GetLandLordByUUID';
import { useUrlSearchParams } from 'use-url-search-params';
import { createShareEntityLink } from '../../../../utils/actions';
import { getFormData, getValueOrPlaceholderString } from './landlord-view.utils';
import { ConfirmationDialog } from '../../../building-blocks/confirmation-dialog/confirmation-dialog';
import { useDeleteMutation } from '../landlord-list/landlord-list.hooks';
import { ViewMode, ViewModeSwitcher } from '../../../building-blocks/view-mode-switcher/view-mode-switcher';
import { EntityViewActions } from '../../../building-blocks/entity-view-actions/entity-view-actions';
import { SaveBar } from '../../../building-blocks/save-bar/save-bar';
import { landlordBlockSchema, landlordValidationSchema } from './landlord-view.validation';
import { ValidationError } from 'yup';
import { yupToFormErrors } from 'formik';
import { clearUpPhoneNumber } from '../../../../utils/validation/input-validation';
import { READ_ONLY_PROPS } from '../../../../utils/constants';
import { useFormValidation } from '../../../../utils/validation/validation-utils';
import { useDateTimeFormat } from '../../../../utils/hooks/dateTimeFormat';
import FormBlockingDialog from '../../../building-blocks/form-blocking-dialog/form-blocking-dialog';
import { DefaultMultiSelectItem } from '../../../../utils/types';
import { ListingCardContainer } from '../../../building-blocks/listing-card-container/listing-card-container';
import LandlordContactData from '../../landlord-contact-data/landlord-contact-data';
import { useLandlordUserData } from '../../landlord-contact-data/landlord-contact-data.hooks';

const ActivationBar: FC = ({ children }) => <div className={styles.ActivationBar}>{children}</div>;

const ProfileSection: FC<{ mode: ViewMode; hasMetaData: boolean }> = ({ children, mode, hasMetaData }) => {
  return mode === ViewMode.VIEW ? (
    <Card
      className={classNames(styles.ProfileSection, styles.ProfileSection__readonly, {
        [styles.ProfileSection__withMetaData]: hasMetaData
      })}
    >
      {children}
    </Card>
  ) : (
    <div
      className={classNames(styles.ProfileSection, {
        [styles.ProfileSection__withMetaData]: hasMetaData
      })}
    >
      {children}
    </div>
  );
};

interface MetaDataProps {
  who?: string | null;
  when?: Date | string | null;
  label?: string;
  value?: string | null;
}

const ReadonlyMetaData: FC<MetaDataProps> = ({ label, who, when, value }) => {
  const { formatDateTimeWithSuffix } = useDateTimeFormat();

  return (
    <div
      className={classNames(styles.ReadonlyMetaData, {
        [styles.ReadonlyMetaData__noSpace]: !label
      })}
    >
      <span className={styles.ReadonlyMetaDataLabel}>{label}</span>
      {who ? (
        <span>
          {who + ','}
          <br />
          {when && <time dateTime={when ? new Date(when)?.toISOString() : ''}>{formatDateTimeWithSuffix(when)}</time>}
        </span>
      ) : (
        !value && '-'
      )}
      {value && <span>{value}</span>}
    </div>
  );
};

export const LandlordView: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // reference to the h1 of the listings section (needed to automatically scroll to this section)
  const listingsRef = useRef<HTMLHeadingElement>(null);

  const { uuid, viewMode } = useParams();
  const { pathname } = useLocation();
  const [params] = useUrlSearchParams({}, {});
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const { formatDateTimeWithSuffix } = useDateTimeFormat();

  const formState = useSelector<GlobalState, LandlordFormDataState>((state) => state.landlordFormData);
  const {
    id,
    approvalState,
    contactType,
    companyName,
    firstName,
    lastName,
    phone,
    email,
    gender,
    street,
    houseNumber,
    postalCode,
    city,
    notes,
    createdAt,
    verifiedAt,
    verifiedBy,
    modifiedAt,
    lastEditedBy,
    isBlocked,
    blockedBy,
    blockedReason,
    blockedAt
  } = formState;

  const [fetchLandlord, { loading, data: queryResultData, error: queryError, refetch }] = useLazyQuery<
    GetLandLordByUUID,
    GetLandLordByUUIDVariables
  >(GET_LANDLORD_BY_UUID, { variables: { uuid }, fetchPolicy: 'no-cache' });
  const { createLandlord, updateLandlord } = useCreateAndUpdateMutations(formState, id);
  const { verifyLandlord, blockLandlord } = useVerifyAndBlockMutations(blockedReason, id, email);
  const { deleteLandlord } = useDeleteMutation(id);
  const {
    formErrorState: [formErrors, setFormErrors],
    getValidationPropsForField,
    clearFormError,
    validateState
  } = useFormValidation(landlordValidationSchema, formState);
  const isLoaded = uuid ? formState.uuid === uuid : true;
  useMarkAsSeen(formState, isLoaded);

  const [forceRerenderFormBLocker, setForceRerenderFormBlocker] = useState(0);
  const {
    landlordProfileData,
    getLandLordQuery: { loading: loadingLandlord, data: userData, refetch: refetchLandlord }
  } = useLandlordUserData(queryResultData?.landlordByUUID?.id);

  const isPrivateContactType = contactType === ContactType.PRIVATE;
  const isEditMode = viewMode === undefined || viewMode === ViewMode.EDIT.toLowerCase();
  const isCreationMode = uuid === undefined || uuid === 'new';

  const contactTypeItems = Object.keys(ContactType).map((key) => ({
    text: t(`enums.contactType.${key}`),
    id: key
  }));

  const salutationItems: DefaultMultiSelectItem[] = [
    { id: Gender.MALE, text: t(`enums.salutation.${Gender.MALE}`) },
    { id: Gender.FEMALE, text: t(`enums.salutation.${Gender.FEMALE}`) },
    { id: Gender.DIVERSE, text: t('formFields.placeholder.none') }
  ];

  /**
   * Props helpers
   */
  const inputReadOnlyProps = useMemo(() => {
    return isEditMode ? {} : READ_ONLY_PROPS;
  }, [isEditMode]);

  const translatedInputLabelProps = useCallback(
    (key: string, optionalPlaceholder?: string) => ({
      labelText: t(key) as string,
      placeholder: t(optionalPlaceholder || key)
    }),
    [t]
  );

  const updateApprovalState = (approved: boolean): void => {
    if (approved) {
      verifyLandlord().then(() => refetch && refetch());
      dispatch(setApprovalState(ApprovalType.Approved));
      dispatch(setIsBlocked(false));
      dispatch(setBlockedReason(''));
    } else {
      setOpenBlockModal(true);
    }
  };

  useEffect(() => {
    // When the user selects a listing from the landlord page and returns to the landlord page
    // by clicking the "landlord" element in the breadcrumb navigation or the back button,
    // we directly jump to the listing section of the landlord page
    if (pathname.includes('listings') && queryResultData) {
      listingsRef.current?.scrollIntoView();
    }
  }, [queryResultData]);

  useEffect(() => {
    if (uuid && uuid !== 'new' && !loading && !queryResultData && !queryError) {
      fetchLandlord();
    }
  }, [uuid, loading, queryResultData]);

  useEffect(() => {
    if (queryResultData && queryResultData.landlordByUUID) {
      dispatch(setFormData(getFormData(queryResultData.landlordByUUID)));
    }
  }, [queryResultData]);

  useEffect(() => {
    if (uuid && !viewMode) {
      navigate('./view');
    }
  }, [uuid, viewMode]);

  useEffect(() => {
    setForceRerenderFormBlocker(-1);
    setTimeout(() => {
      setForceRerenderFormBlocker(0);
    });
  }, [viewMode]);
  return (
    <div>
      <BreadcrumbNavigation
        resolver={[
          null,
          (segment) => {
            return segment === 'new' ? t('views.landlord.newLandlord') : `${firstName} ${lastName}`;
          },
          () => null
        ]}
      />
      <BackNavigation
        headline={isCreationMode ? `${t('views.landlord.newLandlord')}` : `${firstName} ${lastName}`}
        navigateTo="/landlord"
      />

      <ActionBar>
        <ActionBarLeft>
          <Toggle
            id="toggleClearance"
            labelA={t('views.landlord.accountNotApproved')}
            labelB={t('views.landlord.accountApproved')}
            className={approvalState === ApprovalType.Inactivated ? '' : 'bx--toggle-input--colored'}
            toggled={approvalState === ApprovalType.Approved}
            onToggle={updateApprovalState}
            disabled={isCreationMode || approvalState === ApprovalType.Inactivated}
          />
        </ActionBarLeft>
        <ActionBarCenter>
          {!isCreationMode && (
            <EntityViewActions
              onDelete={() => setDeleteModal(true)}
              getShareByMailContent={() => ({
                mailBody: t('sharingMail.landlord.sharingMailBody', {
                  link: createShareEntityLink()
                }),
                mailSubject: t('sharingMail.landlord.sharingMailSubject', {
                  name: `${firstName} ${lastName}`
                })
              })}
            />
          )}
        </ActionBarCenter>
        <ActionBarRight>
          {!isCreationMode && <ViewModeSwitcher viewMode={isEditMode ? ViewMode.EDIT : ViewMode.VIEW} />}
        </ActionBarRight>
      </ActionBar>

      <ConfirmationDialog
        open={openDeleteModal}
        title={`${t('views.landlord.modalDelete.title')}`}
        headline={`${t('views.landlord.modalDelete.headline')}`}
        text={`${email ? t('views.landlord.modalDelete.text') : t('views.landlord.modalDelete.textNoEmail')}`}
        primaryActionLabel={`${t('actions.deleteAccount')}`}
        onPrimaryActionClick={() => {
          deleteLandlord().then(() => {
            navigate('/landlord');
          });
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />

      <ContentView className={classNames(styles.Content, 'global-content-wrapper-block')}>
        {!isCreationMode && (
          <ContentViewSection title={t('main.account')} anchorId="account">
            <Card className={styles.AccountCard}>
              <time dateTime={createdAt ? new Date(createdAt)?.toISOString() : ''}>
                <TextInput
                  id="registerDate"
                  labelText={t('formFields.registration') as string}
                  value={formatDateTimeWithSuffix(createdAt)}
                  {...READ_ONLY_PROPS}
                />
              </time>

              <div className={styles.MetaDataColumn}>
                <div className={styles.ApprovalState}>
                  <span className={styles.ApprovalStateLabel}>{t('formFields.status')}</span>
                  <ApprovalState type={approvalState} />
                </div>
                {verifiedAt && verifiedBy && <ReadonlyMetaData who={verifiedBy} when={verifiedAt} />}
                {isBlocked && blockedBy && <ReadonlyMetaData who={blockedBy} when={blockedAt} />}
                {isBlocked && <ReadonlyMetaData label={t('formFields.blockedReason')} value={blockedReason} />}
              </div>
              <ReadonlyMetaData label={t('formFields.activation')} who={verifiedBy} when={verifiedAt} />
              {approvalState === ApprovalType.Inactivated && (
                <ActivationBar>
                  <Button kind={'secondary'} onClick={() => updateApprovalState(false)}>
                    {t('actions.blockAccount')}
                  </Button>
                  <Button onClick={() => updateApprovalState(true)} type="submit" form="profile-data">
                    {t('actions.activateAccount')}
                  </Button>
                </ActivationBar>
              )}
            </Card>
          </ContentViewSection>
        )}
        <ContentViewSection title={t('views.landlord.profile')} anchorId="profile" showRequiredToolTip={isEditMode}>
          <ProfileSection mode={isEditMode ? ViewMode.EDIT : ViewMode.VIEW} hasMetaData={!isCreationMode}>
            {!isCreationMode && (
              <div className={styles.MetaData}>
                <ReadonlyMetaData label={t('formFields.lastEdited')} who={lastEditedBy} when={modifiedAt} />
              </div>
            )}
            {isEditMode && (
              <Form
                className={styles.ProfileData}
                id="profile-data"
                onSubmit={(event) => {
                  // prevent submit since we have custom validation logic and requests
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <RadioButtonGroup
                  name="ProfileType"
                  valueSelected={contactType}
                  className={styles.ContactTypeSelection}
                  onChange={(value) => dispatch(setContactType(value as ContactType))}
                >
                  {contactTypeItems.map((item) => (
                    <RadioButton id={item.id} key={item.id} value={item.id} labelText={item.text} />
                  ))}
                </RadioButtonGroup>
                {isPrivateContactType ? null : (
                  <RequiredFormItem className={styles.SubGridSpanFull} isEditMode={isEditMode}>
                    <TextInput
                      id="company"
                      value={getValueOrPlaceholderString(companyName, isEditMode)}
                      {...translatedInputLabelProps(
                        contactType === ContactType.COMPANY ? 'formFields.company' : 'formFields.publicInstitution',
                        contactType === ContactType.COMPANY
                          ? 'formFields.nameOfCompany'
                          : 'formFields.nameOfPublicInstitution'
                      )}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('companyName', (event) => {
                        dispatch(setCompanyName(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                )}
                <div className={styles.SubGridSpanFull}>
                  {isEditMode && (
                    <RequiredFormItem isEditMode={isEditMode} className={styles.salutation}>
                      <Dropdown
                        id="salutation"
                        items={salutationItems}
                        label={''}
                        itemToString={(item) => (item ? item.text : '')}
                        titleText={t('formFields.salutation') as string}
                        selectedItem={gender ? salutationItems.find((item) => item.id === gender) : null}
                        {...inputReadOnlyProps}
                        {...getValidationPropsForField(
                          'salutation',
                          ({ selectedItem }: { selectedItem: DefaultMultiSelectItem }) => {
                            selectedItem && dispatch(setGender(selectedItem.id as Gender));
                          }
                        )}
                      />
                    </RequiredFormItem>
                  )}
                  <RequiredFormItem isEditMode={isEditMode}>
                    <TextInput
                      id="lastName"
                      value={getValueOrPlaceholderString(lastName, isEditMode)}
                      {...translatedInputLabelProps('formFields.lastName')}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('lastName', (event) => {
                        dispatch(setLastName(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                  <TextInput
                    id="firstName"
                    value={getValueOrPlaceholderString(firstName, isEditMode)}
                    {...translatedInputLabelProps('formFields.firstName')}
                    {...inputReadOnlyProps}
                    {...getValidationPropsForField('firstName', (event) => {
                      dispatch(setFirstName(event.target.value));
                    })}
                  />
                </div>
                <div className={styles.SubGridSpanFull}>
                  <RequiredFormItem isEditMode={isEditMode}>
                    <TextInput
                      id="tel"
                      value={getValueOrPlaceholderString(phone, isEditMode)}
                      {...translatedInputLabelProps('formFields.telephoneNumber')}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('phone', (event) => {
                        dispatch(setTelephoneNumber(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                  <RequiredFormItem isEditMode={isEditMode}>
                    <TextInput
                      id="mail"
                      value={getValueOrPlaceholderString(email, isEditMode)}
                      {...translatedInputLabelProps('formFields.emailAddress')}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('email', (event) => {
                        dispatch(setEmail(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                </div>
                <div className={classNames(styles.SubGridSpanFull, styles.street)}>
                  <RequiredFormItem isEditMode={isEditMode}>
                    <TextInput
                      id="street"
                      value={getValueOrPlaceholderString(street, isEditMode)}
                      {...translatedInputLabelProps('formFields.street')}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('street', (event) => {
                        dispatch(setStreet(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                  <RequiredFormItem isEditMode={isEditMode}>
                    <TextInput
                      id="house-number"
                      value={getValueOrPlaceholderString(houseNumber, isEditMode)}
                      {...translatedInputLabelProps(
                        'formFields.houseNumberAbbreviation',
                        'formFields.placeholder.none'
                      )}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('houseNumber', (event) => {
                        dispatch(setHouseNumber(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                </div>
                <div className={classNames(styles.SubGridSpanFull, styles.city)}>
                  <RequiredFormItem isEditMode={isEditMode}>
                    <TextInput
                      id="postal-code"
                      value={getValueOrPlaceholderString(postalCode, isEditMode)}
                      {...translatedInputLabelProps('formFields.postalCode', 'formFields.postalCodeAbbreviation')}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('postalCode', (event) => {
                        dispatch(setPostalCode(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                  <RequiredFormItem isEditMode={isEditMode}>
                    <TextInput
                      id="city"
                      value={getValueOrPlaceholderString(city, isEditMode)}
                      {...translatedInputLabelProps('formFields.place')}
                      {...inputReadOnlyProps}
                      {...getValidationPropsForField('city', (event) => {
                        dispatch(setCity(event.target.value));
                      })}
                    />
                  </RequiredFormItem>
                </div>
                <TextAreaCount
                  id="notes"
                  value={getValueOrPlaceholderString(notes, isEditMode)}
                  maxLength={400}
                  {...translatedInputLabelProps('formFields.notes')}
                  {...inputReadOnlyProps}
                  {...getValidationPropsForField('notes', (event) => {
                    dispatch(setNotes(event.target.value));
                  })}
                />
              </Form>
            )}
            {!isEditMode && (
              <div className={styles.ViewData}>
                <div className={styles.ContactData}>
                  <div>
                    <h3 id="contact-data">{t('views.landlord.contactData')}</h3>
                    {companyName && <p aria-labelledby="contact-data">{companyName}</p>}
                    <p aria-labelledby="contact-data">{gender ? t(`enums.salutation.${gender}`) : ''}</p>
                    <p aria-labelledby="contact-data">{firstName + ' ' + lastName}</p>
                    <p aria-labelledby="contact-data">{email}</p>
                    <p aria-labelledby="contact-data">{phone}</p>
                  </div>
                  {(street || postalCode || city) && (
                    <div>
                      <h3 id="address-data">{t('views.landlord.address')}</h3>
                      {street && <p aria-labelledby="address-data">{street + ' ' + houseNumber}</p>}
                      <p aria-labelledby="address-data">{postalCode + ' ' + city}</p>
                    </div>
                  )}
                </div>
                <TextAreaCount
                  id="notes"
                  value={getValueOrPlaceholderString(notes, isEditMode)}
                  maxLength={400}
                  {...translatedInputLabelProps('formFields.notes')}
                  {...inputReadOnlyProps}
                  {...getValidationPropsForField('notes', (event) => {
                    dispatch(setNotes(event.target.value));
                  })}
                />
              </div>
            )}
          </ProfileSection>
        </ContentViewSection>
        <ContentViewSection title={t('views.account.contactData')} anchorId="contact">
          <LandlordContactData
            landlordId={queryResultData?.landlordByUUID?.id}
            landlordProfileData={landlordProfileData}
            refetch={refetchLandlord}
            userData={userData}
            loading={loadingLandlord}
          />
        </ContentViewSection>
        <ContentViewSection title={t('views.landlord.listings')} anchorId="listings" ref={listingsRef}>
          <ListingCardContainer
            listings={queryResultData?.landlordByUUID?.listings}
            emptyText={t('views.landlord.createListingHint') as string}
            editable={true}
            refetch={refetch}
          />
          <Button
            disabled={isCreationMode}
            onClick={() => {
              navigate('../../../listings/new');
            }}
          >
            {t('actions.createListing')}
          </Button>
        </ContentViewSection>
      </ContentView>

      <ConfirmationDialog
        open={openBlockModal}
        title={`${t('views.landlord.modalBlocked.title')}`}
        text={`${t('views.landlord.modalBlocked.text')}`}
        primaryActionLabel={`${t('actions.save')}`}
        selectorPrimaryFocus={'textarea'}
        onPrimaryActionClick={() => {
          landlordBlockSchema
            .validate(formState)
            .then(() => {
              // value is valid, remove it from existing errors
              clearFormError('blockedReason');
              blockLandlord();
              dispatch(setApprovalState(ApprovalType.Blocked));
              dispatch(setIsBlocked(true));
              setOpenBlockModal(false);
            })
            .catch((errors: ValidationError) => {
              // value is invalid, add it to existing errors
              const newFormErrors = yupToFormErrors(errors);
              const mergedFormErrors = Object.assign({}, formErrors, newFormErrors);
              setFormErrors(mergedFormErrors);
            });
        }}
        onCloseClick={() => {
          setOpenBlockModal(false);
          dispatch(setBlockedReason(''));
          clearFormError('blockedReason');
        }}
      >
        <TextAreaCount
          labelText={t('views.landlord.modalBlocked.label')}
          maxLength={400}
          placeholder={t('views.landlord.modalBlocked.placeholder')}
          {...getValidationPropsForField('blockedReason', (event) => dispatch(setBlockedReason(event.target.value)))}
        />
      </ConfirmationDialog>

      {/* FormBlockingDIalog needs to be force rerendered to prevent buggy behaviour */}
      {forceRerenderFormBLocker === 0 && (
        <FormBlockingDialog onResetForm={() => dispatch(resetForm())} currentlyEditedItemUUID={uuid} />
      )}

      {isEditMode && (
        <>
          <SaveBar trigger={params}>
            <Button
              kind={'secondary'}
              onClick={() => {
                navigate('/landlord');
              }}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              onClick={() => {
                if (phone) {
                  // user is allowed to input anything, we afterwords clean up disallowed characters
                  dispatch(setTelephoneNumber(clearUpPhoneNumber(phone)));
                }

                validateState(() => {
                  isCreationMode
                    ? createLandlord().then((result) => {
                        if (result && result.data && !result.errors) {
                          const uuid = result.data.addLandlord;
                          dispatch(resetForm());
                          navigate(`/landlord/${uuid}/view`);
                        }
                      })
                    : updateLandlord().then((result) => {
                        if (result && result.data && !result.errors) {
                          refetchLandlord && refetchLandlord();
                          refetch &&
                            refetch().then(() => {
                              navigate(`/landlord/${uuid}/view`);
                            });
                        }
                      });
                });
              }}
              type="submit"
              form="profile-data"
            >
              {t('actions.save')}
            </Button>
          </SaveBar>
        </>
      )}
    </div>
  );
};

export default LandlordView;
