import { FetchResult, MutationFunctionOptions, QueryResult, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { GET_LANDLORD_BY_ID, PUT_UPDATE_LANDLORD } from '../../../graphql/queries/landlord';
import { GetLandLordById, GetLandLordByIdVariables } from '../../../graphql/types/GetLandLordById';
import { UpdateLandlord, UpdateLandlordVariables } from '../../../graphql/types/UpdateLandlord';
import { GlobalState } from '../../../redux/store';
import { useQueryResultToast } from '../../../utils/hooks/query-result-toast';
import { LandlordProfileDataState } from '../account/account-view.state';

export const useLandlordUserData = (
  landlordId?: string
): {
  getLandLordQuery: QueryResult<GetLandLordById, GetLandLordByIdVariables>;
  landlordProfileData: LandlordProfileDataState;
  updateLandlord: (
    options?: MutationFunctionOptions<UpdateLandlord, UpdateLandlordVariables> | undefined
  ) => Promise<FetchResult<UpdateLandlord>>;
} => {
  const landlordProfileData = useSelector<GlobalState, LandlordProfileDataState>((state) => state.landlordProfileData);
  const getLandLordQuery = useQuery<GetLandLordById, GetLandLordByIdVariables>(GET_LANDLORD_BY_ID, {
    variables: {
      id: landlordId ?? ''
    },
    fetchPolicy: 'no-cache'
  });

  const [updateLandlord, { data: updateResultData, error: updateLandlordError }] = useMutation<
    UpdateLandlord,
    UpdateLandlordVariables
  >(PUT_UPDATE_LANDLORD);

  useQueryResultToast(updateResultData, updateLandlordError, {
    error: {
      titleKey: 'notifications.landlordUpdateError',
      subTitleKey: 'notifications.landlordUpdateErrorSubtitle'
    }
  });

  return {
    landlordProfileData,
    getLandLordQuery,
    updateLandlord
  };
};
